import React, { FC, PropsWithChildren } from 'react';
import IconButton from '../IconButton/IconButton';
import './Modal.scss';

interface IModalProps {
    open: boolean;
    onClose: () => void;
}

type ModalProps = PropsWithChildren<IModalProps>;

const Modal: FC<ModalProps> = ({ children, open, onClose }) => {
    return (
        <dialog className="modal" open={open}>
            <header className="modal--header">
                <IconButton iconName="close" size="sm" onClick={onClose} ariaLabel="Close modal button" type="button" />
            </header>
            <section className="modal--content">{children}</section>
        </dialog>
    );
};

export default Modal;
