import { IconAlert } from './Icon-alert.icon';
import { IconAmount } from './Icon-amount.icon';
import { IconArchive } from './Icon-archive.icon';
import { IconArrowLeft } from './Icon-arrow-left.icon';
import { IconAsterisk } from './Icon-asterisk.icon';
import { IconAt } from './Icon-at.icon';
import { IconBalloonFill } from './Icon-balloon-fill.icon';
import { IconBalloonHeartFill } from './Icon-balloon-heart-fill.icon';
import { IconBalloonHeart } from './Icon-balloon-heart.icon';
import { IconBalloon } from './Icon-balloon.icon';
import { IconBathSolid } from './Icon-bath-solid.icon';
import { IconBox2Fill } from './Icon-box-2-fill.icon';
import { IconBox2 } from './Icon-box-2.icon';
import { IconBoxSeamFill } from './Icon-box-seam-fill.icon';
import { IconBoxSeam } from './Icon-box-seam.icon';
import { IconCalendarAddDate } from './Icon-calendar-add-date.icon';
import { IconCart } from './Icon-cart.icon';
import { IconChat } from './Icon-chat.icon';
import { IconCheckCircle } from './Icon-check-circle.icon';
import { IconCheck } from './Icon-check.icon';
import { IconCheckout } from './Icon-checkout.icon';
import { IconChevronDown } from './Icon-chevron-down.icon';
import { IconChevronLeft } from './Icon-chevron-left.icon';
import { IconChevronRight } from './Icon-chevron-right.icon';
import { IconChevronUp } from './Icon-chevron-up.icon';
import { IconDashboard } from './Icon-dashboard.icon';
import { IconEdit } from './Icon-edit.icon';
import { IconEggFried } from './Icon-egg-fried.icon';
import { IconEmail } from './Icon-email.icon';
import { IconEye } from './Icon-eye.icon';
import { IconGear } from './Icon-gear.icon';
import { IconGeoAltFill } from './Icon-geo-alt-fill.icon';
import { IconGeoAlt } from './Icon-geo-alt.icon';
import { IconGeoFill } from './Icon-geo-fill.icon';
import { IconGeo } from './Icon-geo.icon';
import { IconHome } from './Icon-home.icon';
import { IconImage } from './Icon-image.icon';
import { IconInfo } from './Icon-info.icon';
import { IconInstagram } from './Icon-instagram.icon';
import { IconKlarna } from './Icon-klarna.icon';
import { IconLoading } from './Icon-loading.icon';
import { IconLogin } from './Icon-login.icon';
import { IconMail } from './Icon-mail.icon';
import { IconMapFill } from './Icon-map-fill.icon';
import { IconMap } from './Icon-map.icon';
import { IconMarketplace } from './Icon-marketplace.icon';
import { IconMenuDown } from './Icon-menu-down.icon';
import { IconMenu } from './Icon-menu.icon';
import { IconMinus } from './Icon-minus.icon';
import { IconNodeJs } from './Icon-node-js.icon';
import { IconOrder } from './Icon-order.icon';
import { IconOrders } from './Icon-orders.icon';
import { IconPaypal } from './Icon-paypal.icon';
import { IconPaytrail } from './Icon-paytrail.icon';
import { IconPhoneFilled } from './Icon-phone-filled.icon';
import { IconPhone } from './Icon-phone.icon';
import { IconPlusCircle } from './Icon-plus-circle.icon';
import { IconPlus } from './Icon-plus.icon';
import { IconPrint } from './Icon-print.icon';
import { IconProfile } from './Icon-profile.icon';
import { IconRemove } from './Icon-remove.icon';
import { IconSearch } from './Icon-search.icon';
import { IconShowerSolid } from './Icon-shower-solid.icon';
import { IconSignOut } from './Icon-sign-out.icon';
import { IconSignup } from './Icon-signup.icon';
import { IconStarList } from './Icon-star-list.icon';
import { IconTracklist } from './Icon-tracklist.icon';
import { IconTrash } from './Icon-trash.icon';
import { IconTruck } from './Icon-truck.icon';
import { IconUserBadge } from './Icon-user-badge.icon';
import { IconUserLines } from './Icon-user-lines.icon';
import { IconUserList } from './Icon-user-list.icon';
import { IconVinylFilled } from './Icon-vinyl-filled.icon';
import { IconVinyl } from './Icon-vinyl.icon';
import { IconWatsapp } from './Icon-watsapp.icon';
export const icons = [
    IconAlert,
    IconAmount,
    IconArchive,
    IconArrowLeft,
    IconAsterisk,
    IconAt,
    IconBalloonFill,
    IconBalloonHeartFill,
    IconBalloonHeart,
    IconBalloon,
    IconBathSolid,
    IconBox2Fill,
    IconBox2,
    IconBoxSeamFill,
    IconBoxSeam,
    IconCalendarAddDate,
    IconCart,
    IconChat,
    IconCheckCircle,
    IconCheck,
    IconCheckout,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconDashboard,
    IconEdit,
    IconEggFried,
    IconEmail,
    IconEye,
    IconGear,
    IconGeoAltFill,
    IconGeoAlt,
    IconGeoFill,
    IconGeo,
    IconHome,
    IconImage,
    IconInfo,
    IconInstagram,
    IconKlarna,
    IconLoading,
    IconLogin,
    IconMail,
    IconMapFill,
    IconMap,
    IconMarketplace,
    IconMenuDown,
    IconMenu,
    IconMinus,
    IconNodeJs,
    IconOrder,
    IconOrders,
    IconPaypal,
    IconPaytrail,
    IconPhoneFilled,
    IconPhone,
    IconPlusCircle,
    IconPlus,
    IconPrint,
    IconProfile,
    IconRemove,
    IconSearch,
    IconShowerSolid,
    IconSignOut,
    IconSignup,
    IconStarList,
    IconTracklist,
    IconTrash,
    IconTruck,
    IconUserBadge,
    IconUserLines,
    IconUserList,
    IconVinylFilled,
    IconVinyl,
    IconWatsapp
];
