import { useState } from 'react';
import useGetSeenDialog from './useGetSeenDialog';

function useIsDialogOpen() {
    const dialogState = useGetSeenDialog();
    const [isDialogOpen, setIsDialogOpen] = useState(() => dialogState);

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => {
        window.localStorage.setItem('seenDialog', 'true');
        setIsDialogOpen(false);
    };

    return { isDialogOpen, openDialog, closeDialog };
}

export default useIsDialogOpen;
