import React, { FC, PropsWithChildren } from 'react';
import { IconNames } from '../../@types/types';
import { iconsData } from '../Icons/iconsData';
import './Icon.scss';
import clsx from 'clsx';

interface IIconProps {
    iconName?: IconNames;
    size?: 'sm'  | 'md' | 'lg';
}

type IconProps = PropsWithChildren<IIconProps>;

const Icon: FC<IconProps> = ({ iconName, size = 'sm', children }) => {
    const IconElement = children || iconsData[iconName as IconNames];
    const iconStyles = clsx({
        ['icon']: true,
        [`icon--${size}`]: true,
    })
    return <span className={iconStyles}>{IconElement}</span>;
};

export default Icon;
