import React, { FC } from 'react';
import Icon from '../Icon/Icon';
import './ContactAd.scss';
import { IconWatsapp } from '../../icons';

const ContactAd: FC = () => {
    return (
        <div className="contactAd">
            <a href="https://wa.me/358505917322" target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                <Icon>
                    <IconWatsapp />
                </Icon>
            </a>
            <a href="https://www.facebook.com/klaukkalanrengashotelli" target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                <Icon iconName="facebook" />
            </a>
        </div>
    );
};

export default ContactAd;
