import React, { FC, PropsWithChildren } from 'react';
import Icon from '../Icon/Icon';
import { IconNames } from '../../@types/types';
import clsx from 'clsx';
import './IconButton.scss';

interface IIconButtonProps {
    iconName: IconNames;
    ariaLabel: string;
    size: 'sm' | 'md' | 'lg';
    onClick: () => void;
    type?: 'button' | 'submit' | 'reset';
}

type IconButtonProps = PropsWithChildren<IIconButtonProps>;

const IconButton: FC<IconButtonProps> = ({ size, iconName, ariaLabel, onClick, type = 'button' }) => {
    const iconButtonStyles = clsx({
        ['iconButton']: true,
        [`iconButton--${size}`]: true,
    });
    return (
        <button className={iconButtonStyles} type={type} aria-label={ariaLabel} onClick={onClick}>
            <Icon iconName={iconName} size={size} />
        </button>
    );
};

export default IconButton;
